import React, { Component } from 'react';
import Header from '../common/Header';
import PlusSidebar from './PlusSidebar';
import Footer from '../common/Footer';
import MobileNotification from '../common/MobileNotification';
import MobileMessage from '../common/MobileMessage';
import Button from '../common/Button';
import CarProfileBox from './CarProfileBox';
import map  from 'lodash/map';
import size  from 'lodash/size';
import last from 'lodash/last';
import CreateRequestPopup from '../common/CreateRequestPopup';
import PageLoader from '../common/PageLoader';
import CarProfileEmptyState from '../EmptyState/CarProfileEmptyState';
import ToastMessage from '../common/ToastMessage';
import MobileHeaderSearch from '../common/MobileHeaderSearch'
import {appBasePath, imageBasePath, validationMessage} from '../../constants/staticData';
import CustomModal from "../common/CustomModal";
import {MenuItem, Modal} from "react-bootstrap";
import findIndex from "lodash/findIndex";
import CustomScroll from "react-custom-scroll";
import TextInput from "../common/TextInput";
import range from "lodash/range";
import has from "lodash/has";
import {validateFormSubmit} from "../../helpers";
import SelectDropdown from '../common/SelectDropdown';
import Slider from 'react-slick';
import Arrow from "../common/Arrow";
import filter  from 'lodash/filter';

export default class DashboardPlusNext extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.createCarProfile = this.createCarProfile.bind(this);
    this.cancelModal = this.cancelModal.bind(this);

    let startYear = 1990;
    let endYear = (new Date()).getFullYear();
    let years = [];
    let year = startYear;
    map(range(endYear - startYear + 1), () => {
      years.push(year);
      year = year + 1;
    });

    this.state = {
      searchMobile:false,
      notificationVisible: false,
      messageVisible: false,
      isLoading: (props.carProfileReducer && props.carProfileReducer.carProfiles && size(props.carProfileReducer.carProfiles) > 0) ? false : true,
      bookServiceModalVisible: false,
      createCarProfileModal: false,
      engineOilModal: false,
      selecedCarProfileId: '',
      "selectedYear": "Year",
      "allYears": years,
      "manufacturerListDD": false,
      "selectedManufacturer": "Manufacturer",
      "allManufacturer": [],
      "modelListDD": false,
      "selectedModel": "Model",
      "allModel": [],
      packageData: {},
      selectedCylinder: 0,
      currentSlide:1,
      checkAvailability:true,
      carProfileList: [],
      createdNewCar: false,
      submissionError: false,
      selectedCarProfileId: "",
      selectedCustomServiceId:"",
      selectedCustomAdditionalCost:0,
      changeCarProfile:true,
      cylinders:[2,4,6,8,10,12],
      selectedFetchPriceCylinder:"",
      showFetchPriceCylinder:false
    };


    this.initialFormData = {
      "name": '',
      "make": '',
      "model": '',
      "model_id": '',
      "year": '',
      "plate_no": '',
      "serviceid": 3,
      "terms": true,
      "cylinder": 0
    };
    this.formData = {
      ...this.initialFormData
    };
    this.errors = {
      //"name": false,
      // "email": false,
      // "phone": false,
      "make": false,
      "model": false,
      "model_id": false,
      "year": false,
      "plate_no": false,
      "serviceid": false,
    };
    this.showBookServiceModal = this.showBookServiceModal.bind(this);
    this.showBookNewCarModal = this.showBookNewCarModal.bind(this);
    this.chooseCarDetails = this.chooseCarDetails.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.showOilModal = this.showOilModal.bind(this);

  }

  componentWillMount() {
    console.log("rerender 2")

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);


    this.props.actions.getCarProfileList(this.props.router);
    if (!(this.props.carProfileReducer.carMakeAndModels && this.props.carProfileReducer.carMakeAndModels.length)) {
      this.props.actions.getManufacturers();
    }

      this.props.actions.GetAllPackages();


  }

  componentWillReceiveProps(nextProps) {
    console.log("rerender 3",nextProps.commonReducer)
    if (nextProps.carProfileReducer.carProfiles) {
      this.setState({ 'isLoading': false });
    }

    console.log("@@@@@@@@@@@@@");
    console.log(nextProps.bookingRequestReducer);
    if(nextProps.bookingRequestReducer.toastType){
       if(nextProps.bookingRequestReducer.toastMessage.includes("Car Profile has been created successfully")){
          this.setState({createCarProfileModal : false});
       }
    }
    if(this.state.selectedCustomAdditionalCost == "" && this.props.bookingRequestReducer.packageSelected && this.props.bookingRequestReducer.packageSelected.pricings && this.props.bookingRequestReducer.packageSelected.pricings.length && this.props.bookingRequestReducer.packageSelected.pricings[0] && this.props.bookingRequestReducer.packageSelected.pricings[0].customizations && this.props.bookingRequestReducer.packageSelected.pricings[0].customizations.length ){
      this.updateRadioButton(this.props.bookingRequestReducer.packageSelected.pricings[0].customizations[0].id,this.props.bookingRequestReducer.packageSelected.pricings[0].customizations[0].additionalCost)
    }
    if(nextProps.carProfileReducer && nextProps.carProfileReducer.carProfiles){
      if(size(nextProps.carProfileReducer.carProfiles)> size(this.state.carProfileList)){

        console.log("new car profile",nextProps.carProfileReducer.carProfiles)
        this.newCreateCar(nextProps.carProfileReducer.carProfiles);
      }
      this.setState({carProfileList: nextProps.carProfileReducer.carProfiles,changeCarProfile:false});
     //

    }


    if(nextProps.bookingRequestReducer && nextProps.bookingRequestReducer.hasOwnProperty('packageSelected') && nextProps.bookingRequestReducer.packageSelected.hasOwnProperty('id')){
       this.setState({
         packageData: nextProps.bookingRequestReducer.packageSelected,
         checkAvailability: false
       });
    }
    else {
      if(nextProps.commonReducer && nextProps.commonReducer.packagesData && nextProps.commonReducer.packagesData.length > 0 ){
      this.setState({
        packageData:  nextProps.commonReducer.packagesData[6],
        checkAvailability: true
      });
    }
    }
    if (nextProps.commonReducer && nextProps.commonReducer.packagesData && nextProps.commonReducer.packagesData.length) {

      this.setState({
        packageData:  nextProps.commonReducer.packagesData[6]
      });
    }


  /*  if (nextProps.carProfileReducer.carProfiles && size(nextProps.carProfileReducer.carProfiles) > 0) {

      let i = 0;
      let firstCarProfile = map(this.reverseArray(nextProps.carProfileReducer.carProfiles), (profile, index) => {
        if(i == 0){
          return profile
        }
        i++
        return false
      })
      if(firstCarProfile && this.state.selectedCarProfileId == "" && nextProps.commonReducer.packagesData && nextProps.commonReducer.packagesData.length) {

        const packageData =  nextProps.commonReducer.packagesData;

        console.log("packageData",packageData)
        this.props.actions.verifyCost(firstCarProfile[0],packageData[0].id);
        this.setState({
          ...this.state,
          selectedCarProfileId: firstCarProfile[0].id,
        });
      }

    }*/

    if (nextProps.carProfileReducer && nextProps.carProfileReducer.carMakeAndModels) {
      let carList = nextProps.carProfileReducer.carMakeAndModels;
      let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
      const carModel = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].carmodel;

      this.setState({
        allManufacturer: nextProps.carProfileReducer.carMakeAndModels,
        isMakeandModelLoaded: true,
        filteredCarModelList: carModel,
      });
    }

  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  showOilModal() {

    if(this.state.engineOilModal){
      this.movetoAdditionalPage()
    }else{


      if(this.props.bookingRequestReducer.packageSelected && this.props.bookingRequestReducer.packageSelected.pricings && this.props.bookingRequestReducer.packageSelected.pricings.length && this.props.bookingRequestReducer.packageSelected.pricings[0] && this.props.bookingRequestReducer.packageSelected.pricings[0].customizations && this.props.bookingRequestReducer.packageSelected.pricings[0].customizations.length == 0 ){
        this.movetoAdditionalPage()

      }else{
        this.setState({ engineOilModal: true });

      }

    }
  }

  closeModal() {
    this.setState({ engineOilModal: false })
  }

  chooseCarDetails() {
    this.setState({
      hideForm:true,
      hideForm1:false,

    });
  }

  toggleNotification(isVisible) {
    this.setState({ 'notificationVisible': isVisible });
  }

  hideModalCallback() {
    this.setState({ bookServiceModalVisible: false });
  }

  toggleMessage(isVisible) {
    this.setState({ 'messageVisible': isVisible });
  }

  showBookNewCarModal() {

    this.setState({
      ...this.state,
      createCarProfileModal: true,
    });

  }



  selectCarProfile(carInfo) {

    if(carInfo.cylinder){

      const packageData =  this.props.commonReducer.packagesData;
      this.props.actions.verifyCost(carInfo,packageData[6].id);
      this.setState({
        ...this.state,
        selectedCarProfileId: carInfo.id,
        changeCarProfile:true,
        checkAvailability:true,
        showFetchPriceCylinder: false
      });
    }else{
      this.setState({
        ...this.state,
        carInfoData: carInfo,
        selectedCarProfileId: carInfo.id,
        showFetchPriceCylinder : true,
        selectedFetchPriceCylinder: "",
        checkAvailability: true
      });
    }

  }

  selectCylinder(cylinder,carInfo) {

    let carInfoData = {
      modelId : carInfo.modelId,
      id : carInfo.id,
      year : carInfo.year,
      cylinder : cylinder,
    };


    const packageData =  this.props.commonReducer.packagesData;

    this.props.actions.verifyCost(carInfoData,packageData[6].id);

    this.setState({
      ...this.state,
      selectedCarProfileId: carInfo.id,
      changeCarProfile:true,
      checkAvailability:true,
      selectedFetchPriceCylinder: cylinder
    });



  }

  onSubmit(e) {
    e.preventDefault();

  }
  onFieldBlur(value, name, validateError) {
    this.formData[name] = value;
    if (has(this.errors, name)) {
      this.errors[name] = validateError;
    }
  }

  showBookServiceModal(e, id) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      ...this.state,
      selecedCarProfileId: id,
      'bookServiceModalVisible': !this.state.bookServiceModalVisible
    });
  }

  newCreateCar(carProfile){
    if(this.state.createdNewCar){
      // console.log(this.reverseArray(carProfile))
      // console.log(this.reverseArray(carProfile).length)
      // console.log(this.reverseArray(carProfile[0]))
      const reverseArrayInfo = this.reverseArray(carProfile);

      const packageData =  this.props.commonReducer.packagesData;
      this.props.actions.verifyCost(reverseArrayInfo[0],packageData[6].id);
      this.setState({
        ...this.state,
        selectedCarProfileId: reverseArrayInfo[0].id,
        createdNewCar: false
      });
    }
  }

  reverseArray(arr){
    let newArray = []
    Object.keys(arr)
      //.sort()
      .reverse()
      .forEach(key => {
        newArray.push(arr[key])
      })

    console.log(newArray)
    return newArray
  }


  cancelModal(){
    this.setState({ createCarProfileModal: false,
      selectedYear: "Year",
      selectedManufacturer: "Manufacturer",
      selectedModel: "Model",
      selectedCylinder: 0 });
    this.initialFormData = {
      "name": '',
      "email": '',
      "phone": '',
      "make": '',
      "model": '',
      "model_id": '',
      "year": '',
      "plate_no": '',
      "serviceid": 3,
      "terms": true,
      "cylinder": 0
    };
    this.formData = {
      ...this.initialFormData
    };


  }


  createCarProfile(){
    let formData = {
      ...this.formData
    };
    let errors = this.errors;
    const validatedForm = validateFormSubmit(formData, errors);
    this.errors = validatedForm.errors;
    if (validatedForm.validForm) {

    formData.cylinder = this.formData.cylinder.name;
    this.setState({createdNewCar: true})
    this.props.actions.setCarProfileAction(
      formData,
      false,
      []
    );

    this.initialFormData = {
      "name": '',
      "email": '',
      "phone": '',
      "make": '',
      "model": '',
      "model_id": '',
      "year": '',
      "plate_no": '',
      "serviceid": 3,
      "terms": true,
      "cylinder": 0
    };
    this.formData = {
      ...this.initialFormData
    };
    this.setState({ createCarProfileModal: false,
      selectedYear: "Year",
      selectedManufacturer: "Manufacturer",
      selectedModel: "Model",
      selectedCylinder: 0 });

    }else {
      this.setState({ submissionError: true });
      return;
    }

    // let requestData = {
    //   "batch_id": batchId,
    //   "chassis_number": carData.chassis_number,
    //   "name": carData.name,
    //   "make": carData.make,
    //   "model": carData.model,
    //   "year": carData.year.toString(),
    //   "model_id": isEditProfile ? carData.modelId : carData.model_id,
    //   "plate_no": carData.plate_no,
    //   "mileage": carData.mileage,
    //   "images": imageIds && imageIds.length ? imageIds : [],
    //   "state": carData.state,
    //   "insurancepolicynumber": carData.insurancepolicynumber,
    //   "insuranceprovider": carData.insuranceprovider,
    //   "registrationnumber": carData.registrationnumber,
    //   "carnotes": carData.carnotes
    // };
  }

  movetoAdditionalPage(){

    this.props.router.push({
        pathname: appBasePath + '/dashboard-plus-additional',
        state: {  selectedCustomServiceId: this.state.selectedCustomServiceId,
                  selectedCustomAdditionalCost: this.state.selectedCustomAdditionalCost,
                  selectedCarProfileId: this.state.selectedCarProfileId,
              }
      }
    )

  }

  select(selectedData){
    this.formData["cylinder"] =selectedData;
    this.setState({selectedCylinder:selectedData});
  }
  autoCompleteManufacturer(value){

    this.setState({ searchManufacturerTerm: value });

    let searchView = "";
    if(value){
      searchView = filter(this.state.allManufacturer, val => {

        if (value != '' && val.name.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allManufacturer;
    }

    this.setState({ 'filteredCarList': searchView });
  }
  autoCompleteModel(value){

    this.setState({ searchModelTerm: value });


    let searchView = "";
    if(value){
      searchView = filter(this.state.filteredCarModelList, val => {

        if (value != '' && val.name.toLowerCase().indexOf(value.toLowerCase()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allFilteredCarModelList;
    }


    this.setState({ 'filteredCarModelList': searchView });
  }

  autoCompleteYear(value){

    this.setState({ searchYearTerm: value });


    let searchView = "";
    if(value){
      searchView = filter(this.state.allYears, val => {

        if (value.toString() != '' && val.toString().indexOf(value.toString()) != -1) {
          return val;
        }
      });

    }else{
      searchView = this.state.allYearsList;
    }

    this.setState({ allYears: searchView });
  }


  updateRadioButton(id,additionalCost) {


    this.setState({ selectedCustomServiceId :id, selectedCustomAdditionalCost :additionalCost })

  }

  render() {


    const settings = {
      focusOnSelect: true,

      dots: false,
      infinite: false,
      autoplay:false,
      mobileFirst:true,
      speed: 500,
      touchMove: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      nextArrow: <Arrow type="next" />,
      prevArrow: <Arrow type="prev" />,
      initialSlide: 0,
    };





    const { router, carProfileReducer, commonReducer, actions } = this.props;


    const carList = this.state.filteredCarList ? this.state.filteredCarList : this.state.allManufacturer;
    let carMakeIndex = findIndex(carList, { id: this.state.selectedCarMakeKey });
    const carModelLoaded = carList && carList.length && carList[carMakeIndex] && carList[carMakeIndex].isCarModelLoaded;

    const length = size(carProfileReducer.carProfiles)
    const carManufacterView = map(carList, (carItem, key) => {


      return (
        <MenuItem

          key={key}
          onClick={() => {
            this.formData["make"] = carItem.name;
            this.props.actions.getCarModels(carItem.id);
            this.setState({
              selectedManufacturer: carItem.name,
              carModel: carItem.carmodel,
              selectedCarMakeKey: carItem.id,
              filteredCarModelList: carItem.carmodel,
              allFilteredCarModelList: carItem.carmodel
            });
            this.errors["make"] = false;
            this.setState({ 'manufacturerListDD': !this.state.manufacturerListDD });

          }}>
          <label>{carItem.name}</label>
        </MenuItem>
      );
    });

    const carModalList = this.state.isMakeandModelLoaded && this.state.filteredCarModelList;
    const cylinderOption = [{name:'2',key: 1}, {name: '4',key: 2}, {name: '6',key: 3},{name: '8',key: 4},{name: '10',key: 5},{name: '12',key: 6}];

    const carModelView = this.state.isMakeandModelLoaded && map(this.state.filteredCarModelList, (carItem, key) => {
      return (
        <MenuItem
          key={key}
          onClick={() => {
            this.formData['model_id'] = carItem.id;
            this.formData['model'] = carItem.name;
            this.setState({ selectedModel: carItem.name });
            this.setState({ 'modelListDD': !this.state.modelListDD });
            this.errors["model_id"] = false;
            this.errors["model"] = false;
          }}>
          <label>{carItem.name}</label>
        </MenuItem>
      );
    });

    const packageDefaultValue = this.props.bookingRequestReducer.packageSelected.hasOwnProperty('id')?this.props.bookingRequestReducer.packageSelected.pricings[0].cost.toFixed(2) :this.state.packageData.hasOwnProperty('defaultCost') ? this.state.packageData.defaultCost.toFixed(2) : '00' ;

    let carInfoData =  this.state.carInfoData;

    return (
      <div>
        {/*Header*/}
        <Header hideOption={()=>this.setState({searchMobile:true})}
        bookingRequestReducer = {this.props.bookingRequestReducer}
        notificationCount={2} commonReducer={commonReducer} notificationCallBack={this.toggleNotification} messageCallBack={this.toggleMessage.bind(this)} router={router} actions={actions} authReducer={this.props.authReducer}/>
        <MobileNotification isVisible={this.state.notificationVisible} backBtnCallBack={this.toggleNotification} />
        <MobileMessage isVisible={this.state.messageVisible} backBtnCallBack={this.toggleMessage.bind(this)} />
        <div className="main-wrapper n-mob-subheader">
          {/*Sidebar*/}

          {! this.state.createCarProfileModal && <PlusSidebar movetoAdditionalPage={()=>this.showOilModal()} changeCarProfile={this.state.changeCarProfile} page={this.state.createCarProfileModal?"createCarProfileModal":"engineOilModal"} engineOilModal={this.state.engineOilModal} router={this.props.router} packageDefaultValue={packageDefaultValue} selectedCustomAdditionalCost={this.state.selectedCustomAdditionalCost} textChange={this.props.bookingRequestReducer.packageSelected.hasOwnProperty('id')} checkAvailability={this.state.checkAvailability} /> }
          {/*message*/}
          {/*<Extra message="Your email account has been verified. We are open for service!" />*/}
          <div className="plus-desktop">
            <div className="page-sec-header quick-booking-header">
              <div className="padwrapper">
                <span className="back-button" onClick={() =>  this.props.router.push(`${appBasePath}`)}><i className="mdi mdi-arrow-left "></i></span>
                <img className="carcility-plus-logo-back" src={`${imageBasePath}/images/plus/carcility-plus-full-logo.png`} alt=""/>

              </div>
            </div>
            {!this.state.isLoading && this.state.packageData ? <div className="inSection">
              <div className="padwrapper carcility-plus-section">
                <div className="myCar-View ">
                  <div className="mycar-block">
                  <div className="myCar-head row">
                    <div className="col-md-6 padding-zero">
                      <p className="black-top">{this.state.packageData.description}</p>
                      <p className="title">{this.state.packageData.name}</p>
                      <p className="time"><img className="banner-clock" src={`${imageBasePath}/images/clock-icon-white.png`} alt=""/>{this.state.packageData.timeDescription}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="right-top">{this.props.bookingRequestReducer.packageSelected.hasOwnProperty('id')? 'Total': 'Starting at'}</p>
                      <p className="amount">{this.props.bookingRequestReducer.packageSelected.hasOwnProperty('id')?this.props.bookingRequestReducer.packageSelected.pricings[0].cost.toFixed(2) :this.state.packageData.hasOwnProperty('defaultCost') ? this.state.packageData.defaultCost.toFixed(2) : '00' } AED</p>
                    </div>
                  </div>
                  <div className="myCar-body row dashCar-body">

                    <div>
                      <h1 className="section-header">Select Car profile to view exact price</h1>
                      {/*Job Updates*/}

                      {/* <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}> */}
                      {length == 0 ?

                      <div className="Slider-align">
<div className=" bookService-card bookService-card-car-plus bookService-card-empty carcility-plus-car-profile-card" >
            <div className="myCar-card" onClick={ (e) => { this.showBookNewCarModal() } }>

              <figcaption className="myCar-card-caption change empty">
                <span><i className="mdi mdi-plus" aria-hidden="true"></i></span>
                <h5>Car Profile</h5>
              </figcaption>
            </div>
          </div>
          </div>



                    :

                        <div className="row">

                            <div className="col-md-4">

                            <CarProfileBox key={0}  type="empty"
                                           router={router}
                                           btnCallBack={(e) => { this.showBookNewCarModal() }}
                            />
                            </div>
                            <div className="col-md-8">
                               <Slider   {...settings}  className="Slider-align">

                            {(carProfileReducer.carProfiles && size(carProfileReducer.carProfiles) > 0) && map(this.reverseArray(carProfileReducer.carProfiles), (profile, index) => {

                              return (

                                <CarProfileBox
                                  key={index+1} {...profile}
                                  btnCallBack={(e) => { this.selectCarProfile( profile) }}
                                  router={router}
                                  showSelection={ this.state.selectedCarProfileId === profile.id ? true : false}
                                />);
                            })}
                          </Slider>
                            </div>



                          </div>
                      }
                      {/* <CreateRequestPopup selecedCarProfileId={this.state.selecedCarProfileId} carProfileReducer={carProfileReducer} router={router} modalStatus={this.state.bookServiceModalVisible} hideModalCallback={this.hideModalCallback.bind(this)} /> */}
                    </div>

                    <div className="cylinder-container row">
                      { this.state.showFetchPriceCylinder && map(this.state.cylinders, (cylinder, key) => {

                        return (
                          <div key={key}  onClick={(e) => { this.selectCylinder( cylinder,carInfoData) }}  className={cylinder == this.state.selectedFetchPriceCylinder? "box selected" :"box"}>
                            <p>{cylinder}</p>
                          </div>
                        )
                      })}

                    </div>
                    {length == 0 ?
                    <div className="top-line"></div>
                    :
                    <div className="top-lines"></div>
                    }

                    <div className="col-md-12 feauturesList padding-zero ">
                      <p className="service-titlelist">Service includes free pickup and drop.</p>
                      <ul className="minHeight">
                        {map(this.state.packageData.services, (service, key) => {

                          return(
                            <li key={key}><i className="mdi mdi-checkbox-marked-circle" aria-hidden="true"></i><span className="name">{service.name}</span></li>
                          )
                        }) }
                         {map(this.state.packageData.benefits, (benfit, key) => {

                      return (
                        <li key={key}><i className="mdi mdi-checkbox-marked-circle" aria-hidden="true"></i> {benfit}</li>
                      )
                    })}
                      </ul>

                    </div>

                    <div className="footer col-md-12">
                      {this.state.changeCarProfile?
                        <div className="col-md-6 padding-zero">

                          <p className="fetch">Fetching price...</p>
                        </div>
                        :
                        <div className="col-md-6 padding-zero">
                          <p className="left-top">{this.props.bookingRequestReducer.packageSelected.hasOwnProperty('id')? 'Total Price': 'Starting at'}</p>
                          <p className="amount">{this.props.bookingRequestReducer.packageSelected.hasOwnProperty('id')?this.props.bookingRequestReducer.packageSelected.pricings[0].cost.toFixed(2) :this.state.packageData.hasOwnProperty('defaultCost') ? this.state.packageData.defaultCost.toFixed(2):  '00'} AED</p>
                        </div>
                      }

                      <div className="col-md-6">
                        <Button btnSize="sm"  btnCallBack={()=>this.showOilModal()} disabled={this.state.checkAvailability} fontSize={14} btnType="red" label="Book Now"  />

                      </div>

                    </div>
                  </div>


                  </div>
                  <div className="myCar-body features row">

                    <div className="col-md-12 padding-zero-plus">
                      <p className="title">Why Carcility Plus?</p>
                      <div className="col-md-12 left-features">
                        <div className="col-md-12">
                          <img className="banner-clock" src={`${imageBasePath}/images/plus/TimeIsMoneySaveAll-Web.svg`} alt=""/>
                          <p className="description">Time is Money.Save Both.</p>
                        </div>
                        <div className="col-md-12">
                          <img className="banner-clock" src={`${imageBasePath}/images/plus/FreePickUp&Drop-Web.svg`} alt=""/>
                          <p className="description">Free Pick up and Drop</p>
                        </div>
                        <div className="col-md-12">
                          <img className="banner-clock" src={`${imageBasePath}/images/plus/WarrantyIncluded-Web.svg`} alt=""/>
                          <p className="description">Warranty included</p>
                        </div>
                      </div>

                    </div>



                  </div>

                </div>
              </div>
            </div> : <PageLoader />}
          </div>
          {/*Mobile*/}
          <div className="plus-mobile carcility-plus-section">
            <div className="page-sec-header plus-header">
              <div className="padwrapper dash-brand">
                <span className="back-button" onClick={() =>  this.props.router.push(`${appBasePath}`)}><i className="mdi mdi-arrow-left "></i></span>
                <img className="carcility-plus-logo" src={`${imageBasePath}/images/plus/carcility-plus-logo.png`} alt=""/>
                <img className="carcility-plus-dashboard-logo" src={`${imageBasePath}/images/plus/carcility-plus-dashboard-logo.png`} alt=""/>

              </div>
              <div className="myCar-head row ">
                <div className="col-sm-6 col-xs-6 padding-zero">
                  <p className="top">{this.state.packageData.description}</p>
                  <p className="title">{this.state.packageData.name}</p>
                  <p className="time"><img className="banner-clock" src={`${imageBasePath}/images/clock-icon-white.png`} alt=""/>{this.state.packageData.timeDescription}</p>
                </div>
                <div className="col-sm-6 col-xs-6 padding-zero-all">
                  <p className="right-top">{this.props.bookingRequestReducer.packageSelected.hasOwnProperty('id')? 'Total': 'Starting at'}</p>
                  <p className="amount">{this.props.bookingRequestReducer.packageSelected.hasOwnProperty('id')?this.props.bookingRequestReducer.packageSelected.pricings[0].cost.toFixed(2) :this.state.packageData.hasOwnProperty('defaultCost')? this.state.packageData.defaultCost.toFixed(2): '00'} AED</p>
                </div>
              </div>
            </div>
            <div className="features-container">
              <div className="myCar-body features row">

                <div className="row">
                  <h1 className="section-header">Select Car profile to view exact price</h1>
                  {/*Job Updates*/}

                  {/* <CustomScroll heightRelativeToParent="calc(100%)" allowOuterScroll={true}> */}

                                 <CarProfileBox  type="empty"
                                                 router={router}
                                                 btnCallBack={(e) => { this.showBookNewCarModal() }}
                                 />
                              <div className="horizontal-flow-car">

                                {(carProfileReducer.carProfiles && size(carProfileReducer.carProfiles) > 0) ? map(this.reverseArray(carProfileReducer.carProfiles), (profile, index) => {
                                  return (
                                    <CarProfileBox key={index} {...profile}
                                                   btnCallBack={(e) => { this.selectCarProfile( profile) }}
                                                   router={router}
                                                   showSelection={this.state.selectedCarProfileId == profile.id? true : false}
                                    />);
                                }) : " "}
                              </div>

                  {/* </CustomScroll> */}

                  <CreateRequestPopup selecedCarProfileId={this.state.selecedCarProfileId} carProfileReducer={carProfileReducer} router={router} modalStatus={this.state.bookServiceModalVisible} hideModalCallback={this.hideModalCallback.bind(this)} />

                </div>
                <div className="cylinder-container row">
                  { this.state.showFetchPriceCylinder && map(this.state.cylinders, (cylinder, key) => {

                    return (
                      <div key={key}  onClick={(e) => { this.selectCylinder( cylinder,carInfoData) }}  className={cylinder == this.state.selectedFetchPriceCylinder? "box selected" :"box"}>
                        <p>{cylinder}</p>
                      </div>
                    )
                  })}

                </div>

                <div className="col-md-4 why-plus-container">
                  <p className="title">Why Carcility Plus ?</p>
                  <div className="col-md-12 row left-features">
                    <div className="col-md-4 col-sm-4 col-xs-4 spaces">
                      <img className="banner-clock-mobile" src={`${imageBasePath}/images/plus/TimeIsMoneySaveAll-Web.svg`} alt=""/>
                      <p className="description">Time is Money. Save Both.</p>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 spaces">
                      <img className="banner-clock-mobile" src={`${imageBasePath}/images/plus/FreePickUp&Drop-Web.svg`} alt=""/>
                      <p className="description">Free Pick up and Drop</p>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-4 spaces">
                      <img className="banner-clock-mobile" src={`${imageBasePath}/images/plus/WarrantyIncluded-Web.svg`} alt=""/>
                      <p className="description">Warranty included</p>
                    </div>
                  </div>

                </div>

                <div className="col-md-6 services-plus-container">
                  <p className="title-right">Service includes free pickup and drop.</p>
                  <ul className="minHeight">
                    {map(this.state.packageData.services, (service, key) => {

                      return (
                        <li key={key}><i className="mdi mdi-checkbox-marked-circle" aria-hidden="true"></i> {service.name}</li>
                      )
                    })}
                    {map(this.state.packageData.benefits, (benfit, key) => {

                      return (
                        <li key={key}><i className="mdi mdi-checkbox-marked-circle" aria-hidden="true"></i> {benfit}</li>
                      )
                      })}

                  </ul>

                </div>

              </div>
            </div>

          </div>


        </div>
        {this.props.bookingRequestReducer.toastMessage && <ToastMessage actions={this.props.actions} show={this.props.bookingRequestReducer.toastMessage && this.props.bookingRequestReducer.toastType ? true : false} type={this.props.bookingRequestReducer.toastType} title={this.props.bookingRequestReducer.toastType + '...'} text={this.props.bookingRequestReducer.toastMessage} />}
        <div className="footerSection footerSection-plus" >
          <Footer {...this.props}/>
        </div>
        {this.state.searchMobile && <div >

          <MobileHeaderSearch mobile={true} actions={this.props.actions} commonReducer={this.props.commonReducer} mobSearchClick={()=>this.setState({searchMobile:false})}/>
        </div>}


        <CustomModal className="plus-engine-oil-modal plus-modal" showModal={this.state.engineOilModal} footer={false} cancelText="Go Back" saveText="Add to Cart"
                     title="Select Engine oil"

                     closeIcon={true}  hideModal={() => { this.setState({ engineOilModal: false }) }}>
          <Modal.Body>
            <div className="details">
              <p className="info">Comprehensive Service</p>

              {(this.props.bookingRequestReducer.packageSelected && this.props.bookingRequestReducer.packageSelected.pricings && this.props.bookingRequestReducer.packageSelected.pricings.length && this.props.bookingRequestReducer.packageSelected.pricings[0] && this.props.bookingRequestReducer.packageSelected.pricings[0].customizations && this.props.bookingRequestReducer.packageSelected.pricings[0].customizations.length && map(this.props.bookingRequestReducer.packageSelected.pricings[0].customizations, (customization, index) => {
                  let additionalCost = customization.additionalCost?parseInt(customization.additionalCost ): 0;


                  return (
                    <div className="form-check promo-item" >

                      <input id={customization.id}  className="radio-custom" onClick={ e => this.updateRadioButton(customization.id,customization.additionalCost) }  name="radio-group" type="radio" checked={index == 0 && this.state.selectedCustomServiceId == ""? true: this.state.selectedCustomServiceId == customization.id? true: false }/>
                      <label htmlFor={customization.id} className="radio-custom-label">{customization.name}</label>
                      <label className="radio-custom-amount">{additionalCost.toFixed(2)} AED</label>
                      <p className="description">
                        {customization.description}
                      </p>
                    </div>
                  )
                })
              )}


            </div>


            <div className="footer">


              <div className="left">
                <p className="amount">AED { (this.state.packageData.defaultCost + this.state.selectedCustomAdditionalCost).toFixed(2)}</p>
              </div>


              <div className="right">
                <Button btnType="transparent" btnSize="sm" btnCallBack={() => { this.setState({ engineOilModal: false }) } } fontSize={15} label="Go Back" />
                <Button btnType="red" btnSize="sm" fontSize={15} label="Book Now" btnCallBack={()=>this.movetoAdditionalPage()}  />
              </div>



            </div>

          </Modal.Body>
        </CustomModal>



        <CustomModal className="plus-modal plus-modal-add-car" showModal={this.state.createCarProfileModal} footer={true} cancelText="Go Back" saveText={this.state.windowWidth > 991 ? "Continue" : "Done"}
                     title={this.state.windowWidth > 991 ? "Add car profile": "Create A Car Profile"}
                     submitCallBack ={() => { this.createCarProfile()}}
                     closeIcon={true}  hideModal={() => { this.cancelModal() }}>
          <Modal.Body>
            <div className="col-md-12 col-sm-12 col-xs-12 plus-new-profile-form">

              <div className="">

                <div className={this.state.hideForm && !this.state.hideForm1? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">Select Manufacturer
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i>
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="manufacturer"
                        id="manufacturer"
                        className="form-control quick-search-input"
                        placeholder="Manufacturer"
                        value={this.state.searchManufacturerTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteManufacturer(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {(carList && carList.length) > 0 ?map(carList, (carItem, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {
                                    this.formData["make"] = carItem.name;
                                    this.props.actions.getCarModels(carItem.id);
                                    this.setState({
                                      hideForm1: true,
                                      hideForm2: false,
                                      selectedManufacturer: carItem.name,
                                      carModel: carItem.carmodel,
                                      selectedCarMakeKey: carItem.id,
                                      filteredCarModelList: carItem.carmodel
                                    });
                                    this.errors["make"] = false;
                                    this.setState({ 'manufacturerListDD': !this.state.manufacturerListDD });

                                  }}>
                                  <label>{carItem.name}</label>
                                </li>
                              );
                            }): <div className="dot-flashing"></div>}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.hideForm  && this.state.hideForm1 && !this.state.hideForm2? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">{this.state.selectedManufacturer} / Select Model
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="model"
                        id="model"
                        className="form-control quick-search-input"
                        placeholder="Model"
                        value={this.state.searchModelTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteModel(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {(carModalList && carModalList.length) > 0 ? map(carModalList, (carItem, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {

                                    this.formData['model_id'] = carItem.id;
                                    this.formData['model'] = carItem.name;
                                    this.setState({
                                      selectedModel: carItem.name,
                                      'modelListDD': !this.state.modelListDD,
                                      hideForm1: true,
                                      hideForm2: true,
                                      hideForm3: false,
                                    });
                                    this.errors["model_id"] = false;
                                    this.errors["model"] = false;


                                  }}>
                                  <label>{carItem.name}</label>
                                </li>
                              );
                            }):
                            <div className="dot-flashing"></div>}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.hideForm  && this.state.hideForm1 && this.state.hideForm2 && !this.state.hideForm3? "form car-details": "hide car-details"}>
                  <div className="form-title quick-search-title">{this.state.selectedManufacturer} / {this.state.selectedModel} / Select Model
                    <span className="close" onClick={(e) => {
                      this.setState({
                        hideForm:false,
                        searchManufacturerTerm:"",
                        searchYearTerm:"",
                        searchModelTerm:""
                      });
                    }}><i className="mdi mdi-close"></i></span>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <input
                        type="text"
                        name="year"
                        id="year"
                        className="form-control quick-search-input"
                        placeholder="Year"
                        value={this.state.searchYearTerm}
                        onChange={e => {
                          e.preventDefault();
                          this.autoCompleteYear(e.target.value );
                        }}
                      />
                      <div className="car-search">
                        <ul>
                          <CustomScroll heightRelativeToParent="433px" allowOuterScroll={true} >
                            {map(this.state.allYears, (list, key) => {
                              return (
                                <li
                                  key={key}
                                  onClick={() => {
                                    this.setState({ selectedYear: list,hideForm3:true,hideForm:false });
                                    this.formData["year"] = list.toString();
                                    this.setState({ 'yearListDD': !this.state.yearListDD });
                                    this.errors["year"] = false;
                                    this.errors["car_details"] = false;
                                    this.formData["car_details"] = "car_details";
                                  }}>
                                  <label>{list}</label>
                                </li>
                              );
                            })}
                          </CustomScroll>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <form className={this.state.hideForm? "hide form": "form"}>
                  <div className="row">
                    <div className="col-12">

                      <div className="car-details-div">
                        <TextInput
                          label="Car Details.*"
                          name="car_details"
                          id="car_details"
                          type="text"
                          value={this.state.selectedManufacturer+ "/" +this.state.selectedModel+ "/" +this.state.selectedYear}
                          showValidationError={this.errors['car_details']}
                          emptyError={validationMessage.car_details.emptyError}
                          validationError={validationMessage.car_details.validationError}
                          onFocus={this.chooseCarDetails.bind(this)} />

                        <span className="close" onClick={(e) => {


                          this.setState({
                            selectedManufacturer:"Manufacturer",
                            selectedModel:"Model",
                            selectedYear:"Year"
                          });

                          this.formData["make"] = "";
                          this.formData['model_id'] = "";
                          this.formData['model'] = "";
                          this.formData["year"] = "";
                          this.formData["car_details"] = "";

                          this.errors["car_details"] = true;



                        }}><i className="mdi mdi-close"></i></span>

                      </div>

                    </div>

                    <div className="col-12">
                      <TextInput
                        label="Enter Plate Number"
                        name="plate_no"
                        id="plate_no"
                        type="text"
                        showValidationError={this.errors['plate_no']}
                        emptyError={validationMessage.platenumber.emptyError}
                        validationError={validationMessage.platenumber.validationError}
                        onBlur={this.onFieldBlur.bind(this)} />
                    </div>
                    <div className="col-12 model-select-plus">
                            <SelectDropdown data={cylinderOption} customPlaceholder="Choose Cylinder" selectedCarProfile={this.state.selectedCylinder!="" && this.state.selectedCylinder} select={this.select.bind(this)} />
                   </div>


                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </CustomModal>
      </div>
    );
  }
}
